import { PartialTheme, ThemeProvider } from '@fluentui/react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import styled from 'styled-components/macro';

import { configureAi, reactPlugin } from './helper/AiHelper';
import { AppRoutes } from './navigation/AppRoutes';
import { useStoreActions, useStoreState } from './store/Store';
import { DarkPalette, DarkSemanticColors, DefaultComponentStyles, DefaultFontStyle, Fonts, Palette, SemanticColors } from './theme/Theme';

const AppContainer = styled.div`
    display: flex;
    min-height: 100vh;
    height: 100vh;
    min-width: 100%;
    width: 100%;
`;

/**
 * The key for the local storage dark theme state.
 */
export const darkThemeStorageKey = 'isDarkTheme';

/**
 * The application component.
 *
 * @returns {ReactElement | null} Main App component or NULL if a dependency is missing.
 */
const App: FunctionComponent = (): ReactElement | null => {
    /**
     * Whether the dark theme is selected.
     */
    const isDarkTheme = useStoreState((state) => state.UserModel.isDarkTheme);

    /**
     * Changes the theme selection in the global store.
     */
    const updateIsDarkTheme = useStoreActions((actions) => actions.UserModel.updateIsDarkTheme);

    /**
     * Changes the local storage dark theme state on change.
     */
    useEffect(() => {
        if (isDarkTheme != null) {
            localStorage.setItem(darkThemeStorageKey, String(isDarkTheme));
        }
    }, [isDarkTheme, updateIsDarkTheme]);

    // Initialize the fluent ui theme.
    const theme: PartialTheme = {
        palette: isDarkTheme ? DarkPalette : Palette,
        defaultFontStyle: DefaultFontStyle,
        components: DefaultComponentStyles(isDarkTheme ? DarkPalette : Palette),
        fonts: Fonts,
        isInverted: isDarkTheme,
        semanticColors: isDarkTheme ? DarkSemanticColors : SemanticColors,
    };

    /**
     * Initialize the application.
     */
    useEffect(() => {
        configureAi('972990c5-f8e1-4b54-b80b-30bb687ad878');
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <AppContainer>
                <AppRoutes />
            </AppContainer>
        </ThemeProvider>
    );
};

export default withAITracking(reactPlugin, App, App.name, 'App-Container');
