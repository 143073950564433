import { ComponentsStyles, IFontStyles, IPalette, IRawStyle, ISemanticColors } from '@fluentui/react';

/**
 * The color palette for the theme.
 */
export const Palette: Partial<IPalette> = {
    black: '#000000',
    blackTranslucent40: '#00000029',
    neutralDark: '#151515',
    neutralLight: '#eaeaea',
    neutralLighter: '#f4f4f4',
    neutralLighterAlt: '#f8f8f8',
    neutralPrimary: '#000000',
    neutralPrimaryAlt: '#2f2f2f',
    neutralQuaternary: '#d0d0d0',
    neutralQuaternaryAlt: '#dadada',
    neutralSecondary: '#373737',
    neutralSecondaryAlt: '',
    neutralTertiary: '#595959',
    neutralTertiaryAlt: '#c8c6c4',
    red: '#F57F6B',
    themeDark: '#006a93',
    themeDarkAlt: '#007dae',
    themeDarker: '#004e6d',
    themeLight: '#c7e0f4',
    themeLighter: '#deecf9',
    themeLighterAlt: '#eff6fc',
    themePrimary: '#008bc1',
    themeSecondary: '#1897c9',
    themeTertiary: '#57b5da',
    white: '#ffffff',
};

/**
 * The color palette for the theme.
 */
export const DarkPalette: Partial<IPalette> = {
    black: '#ffffff',
    blackTranslucent40: '#ffffff15',
    neutralDark: '#c4bfb7',
    neutralLight: '#333333',
    neutralLighter: '#152c3d',
    neutralLighterAlt: '#1B1E1F',
    neutralPrimary: '#DFDFDF',
    neutralPrimaryAlt: '#707070',
    neutralQuaternary: '#274459',
    neutralQuaternaryAlt: '#223e52',
    neutralSecondary: '#8c8c8c',
    neutralTertiary: '#707070',
    neutralTertiaryAlt: '#3e5e75',
    red: '#B23030',
    themeDark: '#006a93',
    themeDarkAlt: '#007dae',
    themeDarker: '#004e6d',
    themeLight: '#c7e0f4',
    themeLighter: '#deecf9',
    themeLighterAlt: '#eff6fc',
    themePrimary: '#008bc1',
    themeSecondary: '#1897c9',
    themeTertiary: '#57b5da',
    white: '#181A1B',
};

/**
 * The semantic colors.
 */
export const SemanticColors: Partial<ISemanticColors> = {};

/**
 * The semantic colors for the dark theme.
 */
export const DarkSemanticColors: Partial<ISemanticColors> = {
    successBackground: '#88B584',
    successIcon: '#004C00',
    infoBackground: '#BABABA',
    infoIcon: '#222222',
    warningBackground: '#D3BE7A',
    warningIcon: '#333333',
    severeWarningBackground: '#D39F7A',
    errorBackground: '#D3887A',
};

/**
 * The fonts for the theme.
 */
export const Fonts: Partial<IFontStyles> = {
    // Control Icons
    small: {
        fontSize: '12px',
        fontWeight: 'regular',
    },
    // Nav Menus, Labels
    medium: {
        fontSize: '14px',
        fontWeight: 'regular',
    },
    // Icons
    mediumPlus: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    // Modal Headlines
    large: {
        fontSize: '20px',
        fontWeight: 'regular',
    },
    // Main Headlines
    xxLarge: {
        fontSize: '30px',
        fontWeight: 'bold',
    },
};

/**
 * The default font style to use for theme.
 */
export const DefaultFontStyle: IRawStyle = {
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontWeight: 'regular',
};

/**
 * Default styles for fluent ui components.
 *
 * @param {IPalette} palette The theme color palette.
 * @returns {ComponentsStyles} The default component styles.
 */
export const DefaultComponentStyles = (palette: Partial<IPalette>): ComponentsStyles => {
    return {
        MessageBar: {
            styles: {
                root: {
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                },
                innerText: {
                    paddingTop: 2,
                },
            },
        },
        PrimaryButton: {
            styles: {
                root: {
                    borderRadius: 2,
                    color: palette.themeSecondary,
                    borderColor: palette.themeSecondary,
                    backgroundColor: 'transparent',
                },
                rootHovered: {
                    color: palette.themePrimary,
                    backgroundColor: palette.themePrimary + '20',
                },
                rootPressed: {
                    color: palette.themeDark,
                    backgroundColor: palette.themePrimary + '40',
                    borderColor: palette.themeDark,
                },
            },
        },
        Label: {
            styles: {
                root: {
                    fontSize: 11,
                },
            },
        },
    };
};
