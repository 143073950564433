import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
    margin: 2%;
    width: 100%;
`;

const Block = styled.p`
    text-align: justify;
`;

const H2 = styled.h2`
    margin-top: 30px;
    font-size: 17px;
`;

const H1 = styled.h1`
    margin-bottom: 27px;
    font-size: 32px;
`;

/**
 * View component for the privacy for Tripdroid.
 *
 * @returns {ReactElement} Privacy.
 */
export const PrivacyApp: FunctionComponent = (): ReactElement => {
    return (
        <Container>
            <div style={{ margin: '15% auto 10% auto', maxWidth: 600 }}>
                <H1>
                    Datenschutzerklärung
                    <br />
                    der App Tripdroid
                </H1>
                <Block>
                    die App Tripdroid wird von Jens Wiemann, wohnhaft in 39126 Magdeburg („Tripdroid“ oder „wir“) bereitgestellt. Wir sind der Datenverantwortliche für die
                    Verarbeitung deiner persönlichen Daten über die App Tripdroid.
                </Block>
                <H2>1. Für die Bereitstellung der Grundfunktion erfasste Daten</H2>
                <Block>
                    Am Anfang deiner Nutzung der App Tripdroid holen wir gemäß Artikel 6 § 1a DSGVO deine Einwilligung ein. Wenn du diese Einwilligung widerrufst, darfst du die App
                    Tripdroid nicht mehr verwenden.
                </Block>
                <h4>{`a) Ausführung der App Tripdroid`}</h4>
                <Block>
                    Bei der Nutzung der App Tripdroid übermittelt dein Smartphone uns automatisch Datum und Uhrzeit des Zugriffs, das Betriebssystem, den Smartphone-Typ, die Menge
                    der übermittelten Daten und die IP-Adresse. Die Verarbeitung dieser Daten ist aus technischen Gründen für die Nutzung der App Tripdroid notwendig. Mit Ausnahme
                    der IP-Adresse unterziehen wir diese Daten einer statistischen Analyse und nutzen sie, um die App Tripdroid zu verbessern [Rechtsgrundlage: Artikel 6 § 1f
                    DSGVO, berechtigtes Interesse: Bereitstellung und Verbesserung der App Tripdroid].
                </Block>
                <h4>{`b) Registrierung eines Nutzerkontos`}</h4>
                <Block>
                    Du kannst die App Tripdroid ohne Nutzerkonto („Konto“) und ohne Eingabe personenbezogener Daten nutzen. Allerdings kannst du die App Tripdroid ohne
                    Benutzerkonto nur auf einem einzigen Smartphone nutzen. Wenn du dein Smartphone verlierst, kannst du deine Daten nicht wiederherstellen.
                </Block>

                <H2>{`2. Speichern deiner Daten - Standort`}</H2>
                <Block>
                    Wenn sich dein Smartphone mit dem Internet verbindet, übermittelt die App Tripdroid deine Daten in verschlüsselter Form an eine zentrale Datenbank. Diese
                    befindet sich in der Europäischen Union und wird vom technischen Dienstleister Microsoft Corporation angeboten. Der technische Dienstleister kann zur Wartung,
                    zum Betreiben oder zur Verwaltung der technischen Infrastruktur auf deine Daten zugreifen.
                </Block>
                <H2>{`3. Speichern deiner Daten - Dauer`}</H2>
                <Block>
                    Wir speichern deine personenbezogenen Daten nur so lange, wie dies für die Zwecke notwendig ist, für die deine personenbezogenen Daten verarbeitet werden.
                </Block>
                <H2>{`4. Analysetools`}</H2>
                <Block>Wir erfassen Daten über die Tripdroid-Nutzung mithilfe von Analysetools, um die App Tripdroid zu verbessern.</Block>
                <h4>{`a) Microsoft App Center`}</h4>
                <Block>
                    Microsoft App Center erstellt Absturzberichte, in denen angegeben wird, welcher Teil der Software möglicherweise den Absturz verursacht hat, sowie dein
                    Smartphone-Modell und Geräte Metriken. Personenbezogene Daten werden nicht erhoben, es ist jedoch nicht auszuschließen, dass sich diese in der Stapelüberwachung
                    oder Absturzbild enthalten sind.
                </Block>
                <H2>{`5. Dienstleister außerhalb der EU`}</H2>
                <Block>
                    Wir können außerdem externe Dienstleister aus Ländern außerhalb der Europäischen Union in Anspruch nehmen, z. B. für Analysefunktionen. Wir werden jedoch alle
                    angemessenen Maßnahmen ergreifen, damit deine Daten gemäß den datenschutzrechtlichen Vorschriften der Europäischen Union im Wege der von der EU-Kommission
                    genehmigten Standard-Datenschutzklauseln geschützt werden. Du kannst eine Kopie des Vertrags mit dem betreffenden Anbieter anfordern, der den angemessenen
                    Schutz deiner Daten implementiert.
                </Block>
                <H2>{`6. Deine Datenschutzrechte`}</H2>
                <Block>
                    Du kannst Auskunft über die über dich gespeicherten Daten verlangen und hast das Recht, deine Daten in einem gängigen maschinenlesbaren Format zu erhalten.
                    Außerdem kannst du in begründeten Fällen die Löschung oder Berichtigung deiner Daten oder die Einschränkung von deren Verarbeitung verlangen beziehungsweise der
                    Verarbeitung deiner Daten widersprechen.
                </Block>
                <H2>{`7. Widerruf deiner Einwilligung und Kontakt`}</H2>
                <Block>
                    Du hast das Recht, jede von dir irgendwann erteilte Einwilligung jederzeit zu widerrufen. Wenn du Fragen hast oder von deinen Rechten Gebrauch machen möchtest,
                    wende dich bitte unter info@jwiemann.com an uns.
                </Block>
                <Block>Bei Beschwerden kannst du dich außerdem an deine zuständige Datenschutzbehörde wenden.</Block>
                <H2>{`8. Änderungen`}</H2>
                <Block>
                    Wir behalten uns das Recht vor, diese Datenschutzrichtlinie zu ändern. Die aktuelle Fassung findest du auf unserer Website: jwiemann.de/datenschutz. Diese
                    Datenschutzrichtlinie wurde zuletzt am 02.08.2022 geändert.
                </Block>
            </div>
        </Container>
    );
};
