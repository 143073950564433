import { Action, action } from 'easy-peasy';

/**
 * Interface for the user model.
 */
export interface IUserModel {
    /**
     * Whether the user wants to see the dark theme.
     */
    isDarkTheme?: boolean;
    /**
     * Action to update the theme choice.
     */
    updateIsDarkTheme: Action<IUserModel, boolean>;
}

/**
 * Initial state of the user model.
 */
export const UserModel: IUserModel = {
    isDarkTheme: true,
    updateIsDarkTheme: action((state, payload) => {
        state.isDarkTheme = payload;
    }),
};
