import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/**
 * React plugin.
 */
const reactPlugin = new ReactPlugin();

/**
 * Application Insights instance.
 */
let appInsights;

/**
 * Configures and initializes application insights.
 *
 * @param {string} aiKey Application insights instrumentation key.
 * @returns {ApplicationInsights} Application Insights configuration.
 */
const configureAi = (aiKey: string): ApplicationInsights => {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: aiKey,
            disableFetchTracking: false,
            enableAutoRouteTracking: true,
            enableRequestHeaderTracking: true,
            autoTrackPageVisitTime: true,
            extensions: [reactPlugin],
        },
    });
    appInsights.loadAppInsights();
    return appInsights;
};

export { configureAi, reactPlugin };
