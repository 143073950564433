import { IStackTokens, Stack, Text } from '@fluentui/react';
import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
    padding: 24px 35px;
    width: 100%;
`;

/**
 * View component for the imprint.
 *
 * @returns {ReactElement} Privacy.
 */
export const Imprint: FunctionComponent = (): ReactElement => {
    /**
     * Basic stack layout tokens.
     */
    const stackTokens: IStackTokens = {
        childrenGap: 50,
    };

    return (
        <Container>
            <Stack horizontalAlign="center" verticalFill verticalAlign="center" tokens={stackTokens}>
                <Text variant={'xxLarge'}>{'Imprint'}</Text>
            </Stack>
        </Container>
    );
};
