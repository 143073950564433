import { IStackTokens, Stack } from '@fluentui/react';
import { brands, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'avataaars';
import React, { FunctionComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const Container = styled.div`
    padding: 24px 35px;
    width: 100%;
`;

const AvatarContainer = styled.div`
    margin-bottom: 50px;
`;

const LinkList = styled.ul`
    list-style-type: none;
    text-align: center;
    padding-left: 0;
`;

const ListItem = styled.li`
    margin: 5px;
`;

const H1Introduction = styled.h1`
    font-size: 20px;
    font-weight: normal;
    padding-left: 10px;
    margin-bottom: 0px;
    color: #aaaaaa;
`;

const H2Name = styled.h2`
    font-size: 35px;
    margin-top: 0;
    margin-bottom: 50px;
`;

const H2 = styled.h2`
    font-size: 20px;
    margin-bottom: 13px;
`;

const IconWrapper = styled.div`
    margin-bottom: 50px;
    display: flex;
    gap: 20px;
`;

const Content = styled.p`
    font-size: 18px;
    margin-bottom: 180px;
`;

const IconLink = styled.a`
    color: #fff;
`;

/**
 * View component for the home view.
 *
 * @returns {ReactElement} Home View.
 */
export const HomeView: FunctionComponent = (): ReactElement => {
    /**
     * Basic stack layout tokens.
     */
    const stackTokens: IStackTokens = {
        childrenGap: 0,
    };

    const LinkStyle: React.CSSProperties = {
        color: '#AAAAAA',
    };

    return (
        <Container>
            <Stack horizontalAlign="center" verticalFill verticalAlign="center" tokens={stackTokens}>
                <AvatarContainer>
                    <Avatar
                        avatarStyle="Circle"
                        topType="ShortHairShortWaved"
                        accessoriesType="Blank"
                        hairColor="BrownDark"
                        facialHairType="BeardLight"
                        facialHairColor="Brown"
                        clotheType="ShirtCrewNeck"
                        clotheColor="Heather"
                        eyeType="Default"
                        eyebrowType="DefaultNatural"
                        mouthType="Default"
                        skinColor="Light"
                    />
                </AvatarContainer>
                <div>
                    <H1Introduction>{`Hello, I'm`}</H1Introduction>
                    <H2Name>{'Jens Wiemann'}</H2Name>
                </div>
                <Content>{`A software engineer and part time freelancer`}</Content>
                <H2>{'Social'}</H2>
                <IconWrapper>
                    <IconLink href="mailto:info@jwiemann.com" target="_blank" rel="noopener noreferrer" aria-label="Mail">
                        <FontAwesomeIcon icon={regular('envelope')} size={'2x'} />
                    </IconLink>
                    <IconLink href="https://github.com/jwiemann" target="_blank" rel="noopener noreferrer" aria-label="Github">
                        <FontAwesomeIcon icon={brands('github')} size={'2x'} />
                    </IconLink>
                    <IconLink href="https://www.linkedin.com/in/jens-wiemann" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                        <FontAwesomeIcon icon={brands('linkedin')} size={'2x'} />
                    </IconLink>
                    <IconLink href="https://twitter.com/Jens_Wiemann" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                        <FontAwesomeIcon icon={brands('twitter')} size={'2x'} />
                    </IconLink>
                </IconWrapper>
                <H2>{'Support me'}</H2>
                <IconWrapper>
                    <IconLink href="https://www.paypal.com/donate/?hosted_button_id=KSNWWC3Y6QGGG" target="_blank" rel="noopener noreferrer" aria-label="Paypal">
                        <FontAwesomeIcon icon={brands('paypal')} size={'2x'} />
                    </IconLink>
                    <IconLink href="https://www.patreon.com/tripdroid" target="_blank" rel="noopener noreferrer" aria-label="Patreon">
                        <FontAwesomeIcon icon={brands('patreon')} size={'2x'} />
                    </IconLink>
                    <IconLink href="https://www.buymeacoffee.com/9fYFX2j" target="_blank" rel="noopener noreferrer" aria-label="Buy me a coffee">
                        <FontAwesomeIcon icon={solid('mug-saucer')} size={'2x'} />
                    </IconLink>
                </IconWrapper>
                <H2>{'Links'}</H2>
                <LinkList>
                    <ListItem>
                        <Link style={LinkStyle} to="/imprint">
                            Imprint
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link style={LinkStyle} to="/privacy">
                            Privacy
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link style={LinkStyle} to="/privacy-tripdroid">
                            Privacy Tripdroid
                        </Link>
                    </ListItem>
                </LinkList>
            </Stack>
        </Container>
    );
};
