import { createStore, createTypedHooks } from 'easy-peasy';

import { GlobalNotificationModel, IGlobalNotificationModel } from './model/GlobalNotificationModel';
import { INavigationModel, NavigationModel } from './model/NavigationModel';
import { IUserModel, UserModel } from './model/UserModel';

/**
 * Interface for the store model.
 */
export interface IStoreModel {
    /**
     * The model for the navigation state.
     */
    NavigationModel: INavigationModel;
    /**
     * Global notification state.
     */
    GlobalNotificationModel: IGlobalNotificationModel;
    /**
     * User state.
     */
    UserModel: IUserModel;
}

/**
 * Model that represents the store.
 */
const StoreModel: IStoreModel = {
    NavigationModel,
    GlobalNotificationModel,
    UserModel,
};

// generate typed hooks
const { useStoreActions, useStoreState, useStoreDispatch, useStore } = createTypedHooks<IStoreModel>();

// offer typed hooks for consumers
export { useStore, useStoreActions, useStoreDispatch, useStoreState };

/**
 * The store.
 */
export const Store = createStore(StoreModel);
