import React, { FunctionComponent, ReactElement } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '../layout/BasicLayout';
import { HomeView } from '../views/HomeView';
import { Imprint } from '../views/Imprint';
import { Privacy } from '../views/Privacy';
import { PrivacyApp } from '../views/PrivacyApp';

/**
 * The routes component.
 *
 * @returns {ReactElement} Router.
 */
export const AppRoutes: FunctionComponent = (): ReactElement => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <BasicLayout>
                            <HomeView />
                        </BasicLayout>
                    }
                />
                <Route
                    path="/imprint"
                    element={
                        <BasicLayout>
                            <Imprint />
                        </BasicLayout>
                    }
                />
                <Route
                    path="/privacy"
                    element={
                        <BasicLayout>
                            <Privacy />
                        </BasicLayout>
                    }
                />
                <Route
                    path="/privacy-tripdroid"
                    element={
                        <BasicLayout>
                            <PrivacyApp />
                        </BasicLayout>
                    }
                />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};
